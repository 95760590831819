import { Component, OnInit } from '@angular/core';

@Component({
  selector: '.app-emails',
  templateUrl: './emails.component.html',
  styles: [` .text-success {color:
    green;
   }
    .text-danger {
      color: red;
    }
    .text-special {
      font-style: italic;
    }`]
})
export class EmailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
