import { Component, OnInit } from '@angular/core';
import { DomainsService } from '../domains.service';
import { error } from 'protractor';


@Component({
  selector: '.app-domains',
  template: `
    <H3>{{errorMsg}}</H3>
    <table border="1"><tr><th>Domain</th></tr><tr *ngFor="let domain of domains"><td>{{domain.domainName}}</td></tr></table>
    `,
  styles: [` .text-success {color:
             green;
            }
             .text-danger {
               color: red;
             }
             .text-special {
               font-style: italic;
             }`]
})
export class DomainsComponent implements OnInit {

  public name = "Dimitri";
  public myId = "domainId";
  public successClass = "text-danger";
  public domains = [];
  public errorMsg;

  constructor(private _domainsService: DomainsService) { }

  ngOnInit(): void {
    this.name = "Vasia";
    this._domainsService.getDomains().subscribe(data=>this.domains = data,
                                                error => this.errorMsg = error);
  }

  setNew() {
    this.name = "Haha";
    console.log("clicked");
  }
}
