import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'
import {HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DomainsComponent } from './domains/domains.component';

import {DomainsService} from './domains.service'
import { EmailsService } from './emails.service';
import { AliasesService } from './aliases.service';
import { EmailsComponent } from './emails/emails.component';
import { AliasesComponent } from './aliases/aliases.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    DomainsComponent,
    EmailsComponent,
    AliasesComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [DomainsService,
              EmailsService,
              AliasesService],
              
  bootstrap: [AppComponent]
})
export class AppModule { }
