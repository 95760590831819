import { Component, OnInit } from '@angular/core';

@Component({
  selector: '.app-aliases',
  template: '<H3>aliases</H3>',
  styleUrls: ['./aliases.component.css']
})
export class AliasesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
