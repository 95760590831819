import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmailsService {

  constructor() { }

  getEmails() {
    return [
      {"id": 1, domain_id: 1, name: "dmitriy@radier.ca", "readOnly": true},
      {"id": 6, domain_id: 2, name: "way2easy", "readOnly": true}
    ];
  }
}
