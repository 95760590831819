import { Component, OnInit } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: '.app-login',
  //template: 'Yahoo',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginError = "";
  user1 = "u1";
  userName="";
  passwd = "";
  resp = "";
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const headers = new HttpHeaders()
    .set('Content-Type','application/json')
    .set('username','dmitriy')
    //.set('password','alisavera');

    //const params = new HttpParams().set('action', 'listDomains');
       
  
    this.http.post('index.php', 
      {"userName": this.userName, "passwd": "OOOOOOOOOOOOOOOOOO"}, {
      headers: headers
    //  params: params
    })
                //.catchError((error:any) => Observable.throw(error.json().error || 'Server error'))
                .pipe(
                  catchError(data => this.loginError = "Error")
                ).subscribe(data => {console.log("data:" + data)});
    console.log("onSubmit");
    //.subscribe(...);
 
  }
}
