<div *ngIf="loginError?.length > 0;"><p class="error">{{loginError}}</p></div>
<div><P class="loginLabel">Please, login</P></div>
<div class="container-fluid">

</div>

<form #loginForm="ngForm" (ngSubmit)="onSubmit()" class="login" novalidate>
<TABLE>
    <TR>
        <TD>Username</TD>
    <TD>
        <input name="userName" [(ngModel)]="userName" required #cUserName="ngModel">
    </TD>
</TR>
<TR>
    <TD>Password</TD>
    <TD>
        <input name="passwd"  type="password" required [(ngModel)]="passwd">
    </TD>
</TR>
<TR>
    <TD></TD>
    <TD align="right"><button  [disabled]="!loginForm.valid">Submit</button></TD>
</TR>
</TABLE>
</form>
  
  <!--<p>First name value: {{ cUserName.value }}</p>
  <p>First name valid: {{ cUserName.valid }}</p>
  <p>Form value: {{ loginForm.value | json }}</p>
  <p>Form valid: {{ loginForm.valid }}</p>-->