import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import {HttpHeaders} from '@angular/common/http';
import { IDomains } from './domains';
import { Observable } from 'rxjs';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/throw';


@Injectable({
  providedIn: 'root'
})
export class DomainsService {

  constructor(private http:HttpClient) { }

  getDomains(): Observable<IDomains[]> {
    const headers = new HttpHeaders()
    .set('Content-Type','application/json')
    //.set('X-Authorization','clientmobileClient1')
    .set('username','dmitriy')
    .set('password','alisavera');

    const params = new HttpParams().set('action', 'listDomains');
    var resp = //this.http.get<IDomains[]>("/assets/data/domains.json");/*{ //.catch(this.errorHandler); 
      this.http.post<IDomains[]>('/api/emailadmin.php', {}, {
      headers: headers,
      params: params});
    console.log(resp);  
    return resp;
    /*[
      {"id": 1, name: "radier.ca", "readOnly": true},
      {"id": 2, name: "way2easy", "readOnly": true}
    ];*/
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error");
  }
}
